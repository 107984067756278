.LoginPage{
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginForm{
    width: 400px;
    display: flex;
    flex-direction: column;
}
.labelField{
    font-size: 16px;
    padding: 5px 0;
}
.inputField{
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 10px;
    outline: none;
}
.buttonSubmit{
    outline: none;
    border: none;
    background: #3a5180;
    cursor: pointer;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 5px 10px;
}
.buttonSubmit:hover{
    background: #5875b1;
}