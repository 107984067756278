.popupPage{

}
.overlayPopup{
    position: absolute;
    background: rgba(0,0,0,0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}
.content{
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    min-height: 300px;
    background: rgb(204,212,227);
    border: rgb(49, 83, 141) 1px solid;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.mainContent{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.text{
    font-size: 20px;
}
.nameInput{
    margin-top: 40px;
    display: flex;
    gap: 10px;
    align-items: center;
}
.name{
    font-size: 20px;
}
.inputField input{
    height: 30px;
    min-width: 250px;
    font-size: 16px;
}
.btnGroup{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.saveBtn,
.cancelBtn{
    min-width: 100px;
    padding: 5px 10px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: white;
    font-weight: 900;
    border-radius: 10px;
    font-size: 20px;
}
.saveBtn{
    background: #04a504;
}
.cancelBtn{
    background: #c40d0d;
}