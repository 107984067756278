.winPage{
    padding: 20px 50px;
}
.environments{
    font-size: 36px;
}
.content{
    margin: 20px 0;
}

.content .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}
