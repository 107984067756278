.menuBar{
    background: linear-gradient(rgb(142, 170, 217), rgb(44, 76, 133));
    height: 100vh;
    width: 200px !important;
    display: flex;
    flex-direction: column;
}
.projectName{
    text-align: center;
    padding: 20px 10px;
    font-size: 36px;
    font-weight: 700;
}
.navigation{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 200px;
}
.mainPage,
.aboutPage,
.winPage{
    color: #000;
    font-size: 20px;
    text-align: left;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
}
.mainPage.active,
.aboutPage.active,
.winPage.active{
    background: rgb(80, 150, 210);
    box-shadow: inset -1px -1px 6px 1px rgba(0, 0, 0, 0.25);
    color: white;
}
.footer{
    text-align: left;
    padding: 10px;
    font-weight: 600;
}