.aboutPage{
    padding: 20px 50px;
}
.titleAbout{
    font-size: 36px;
    font-weight: 700;
}
.descriptions{
    margin: 30px 0;
    font-size: 30px;
}