.ttt tr:nth-child(even) {
    background-color: rgb(207, 213, 233);
    color: #000;
}
.ttt tr:nth-child(odd) {
    background-color: rgb(233, 235, 245);
    color: #000;
}
.actionGroup{
    display: flex;
    align-items: center;
}
.btn {
    border: none;
    cursor: pointer;
    width: 30px;
    height: 20px;
    background: none;
    outline: none;

}

.ttt .p-datatable-thead > tr > th {
    background-color: rgb(70, 116, 193);
    border: 2px solid #fff;
    color: #fff;
}

.ttt .p-datatable-tbody > tr > td {
    border: 2px solid #fff;
}
.ttt .p-datatable-tbody > tr > .idColumn{
    padding: 1rem 15px;
}
.idColumn {
    width: 60px;
}
.ttt .p-datatable-tbody > tr > .action{
    text-align: center;
}
.ttt .p-paginator{
    border: none;
}

.contentTable{

}
.headerTable{
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    padding: 10px;
}
.btnCreateClusters{
    cursor: pointer;
    outline: none;
    border: none;
    background: rgb(70, 116, 193);
    color: white;
    font-size: 24px;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 10px;
}
.refreshData svg{
     cursor: pointer;
}

.btnGroupTable{
    display: flex;
    gap: 10px;
}
.btnGroupTable button{
    min-width: 100px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    font-weight: 700;
    align-items: center;
}
.restoreBtn{
    background: #054fe7;
}
.stopBtn{
    background: #c40d0d;
}
.startBtn{
    background: #04a504;
}
.messagePopup{
    font-size: 24px;
}
.urlField{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}