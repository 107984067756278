.CheckConnectivityPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
}
.text{
    color: white;
    font-size: 56px;
}